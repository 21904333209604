@import "./_components.css";
@import "./_custom.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-size: 16px;
    line-height: 1.25;
  }
  [hidden] {
    display: none !important;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    @apply text-gray-900;
  }
  hr {
    width: 100%;
    height: 1px;
    border: none;
    opacity: 1;
    @apply bg-gray-200;
  }
  input,
  textarea {
    border: none;
    background-color: transparent;
    @apply focus:outline-none;
  }
}
