input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
* {
  font-family: Inter, sans-serif;
  -webkit-tap-highlight-color: transparent;
  @apply selection:bg-success selection:text-white;
}
body {
  @apply text-body-1 bg-light text-gray-900;
}
body:has(.drawer.active, .modal.active) {
  overflow: hidden;
}
button {
  white-space: nowrap;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
input[type="checkbox"] {
  width: 1.25rem;
  border-radius: 0.25rem;
  aspect-ratio: 1/1;
}
fieldset {
  @apply border-0 border-t border-gray-200;
}
svg {
  outline: none;
  @apply [&_*]:outline-none;
}
.inset-0 {
  width: 100%;
  height: 100%;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row > *,
.flex > *,
.flex-center > * {
  min-width: 0;
  min-height: 0;
}
.recharts-responsive-container {
  width: 100% !important;
  overflow: auto;
}
.recharts-wrapper {
  max-width: 100%;
}
.recharts-curve.recharts-area-area {
  pointer-events: none;
}
.recharts-responsive-container {
  overflow: hidden;
}
.date-picker .rmdp-calendar,
.date-picker .rmdp-day-picker,
.date-picker .rmdp-day-picker > div {
  width: 100%;
}
.date-picker .rmdp-day-picker > div {
  @apply space-y-2;
}
.date-picker .rmdp-day {
  @apply w-10 h-10;
}
.date-picker .rmdp-day.rmdp-selected > span {
  @apply bg-primary;
}
.date-picker .rmdp-day.rmdp-today > span {
  @apply bg-dark;
}
.date-picker .rmdp-day > span {
  @apply text-lg rounded transition-colors;
}
.date-picker .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  @apply hover:bg-blue-500;
}
.date-picker .rmdp-day.rmdp-deactive,
.date-picker .rmdp-day.rmdp-disabled {
  @apply text-[#B3B3B3];
}
.date-picker .rmdp-arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.date-picker .rmdp-arrow {
  margin: 0;
}
.date-picker .rmdp-header > div {
  align-items: center;
  gap: 2rem;
  @apply text-primary;
}
.date-picker .rmdp-header-values > span {
  @apply underline text-primary text-lg;
}
