.row {
  display: flex;
  flex-wrap: wrap;
}
.row > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.input-group-text {
  display: inline-flex;
  align-items: center;
  @apply px-2;
  /* first:border-r last:border-l border-light */
}
